@import url(https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@400;500;700&family=Open+Sans:wght@300&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900'); */




:root {
  --primary-color: #d23669;
  --primary-color-light: rgb(255, 167, 196);
}

* {
  box-sizing: border-box;
}

html {
  color: rgba(0, 0, 0, 0.9);
  /* font: 100%/1.75 Morriweather, Georgia, serif; */
}

body {
  font-kerning: normal;
  /* font-family: 'Merriweather','Georgia',serif; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "kern";
          font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

pre,
code {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

a {
  color: #d23669;
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
}

p, pre, ul, ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}

/* h2,h3,h4,h5,h6 {
  font-family: 'Arima Madurai', cursive;
} */


.page-content {
    background: #fff;
    padding: 20px 0;
    min-height: 600px;
    width: 80%;
    white-space: pre-line;
    margin: 0px auto;
  }

@media only screen and (max-width: 600px) {
  .SocialLinks_socialLinks__3vTio {
    padding: 0px 0;
    margin: 10px 0px 0 10px;    
}
}

@media only screen and (min-width: 601px) {
  .SocialLinks_socialLinks__3vTio {
    padding: 0px 0;
    margin: 10px 50px 0 10px;    
}
}


.SocialLinks_socialLinks__3vTio a {


    padding: 0 10px;
}
.SocialLinks_socialLinks__3vTio img {
    width: 30px;
    border-radius: 30px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }

  .SocialLinks_socialLinks__3vTio img:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
  }
/*
font-family: 'Righteous', cursive;
font-family: 'Baloo Tammudu 2', cursive;
font-family: 'Comfortaa', cursive;
font-family: 'Prompt', sans-serif;
font-family: 'Cormorant Garamond', serif;
font-family: 'Cinzel', serif;
font-family: 'Great Vibes', cursive;
font-family: 'Orbitron', sans-serif;
font-family: 'Poiret One', cursive;
font-family: 'Long Cang', cursive;
font-family: 'Yellowtail', cursive;
font-family: 'Bad Script', cursive;
font-family: 'Allura', cursive;

*/
/* * {
  font-family: 'Cinzel', serif;
} */

@media only screen and (max-width: 600px) {
  .Shell_logo__k6Bzc {
    max-width: 180px;
    left: 0px;
    top: 5px;
  }
}

@media only screen and (min-width: 601px) {
  .Shell_logo__k6Bzc {
    max-width: 280px;
    left: 0px;
    top: 0px;
  }
}

@media only screen and (min-width: 1201px) {
  .Shell_logo__k6Bzc {
    max-width: 320px;
    top: 0px;
    left: 10px;
  }
}

.Shell_logo__k6Bzc {
   padding: 10px;
  position: absolute;

  z-index: 100;
}

.Shell_newsletter__2tRig {
  padding: 60px 0 30px 0;
}

.Shell_newsletterShell__3hBDj {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  background: transparent;
  padding: 10px;
  display: block;
  text-align: center;

}
 
.Shell_newsletterTitle__23uWu {
  color: #777;
}
.Shell_newsletterInput__38DXP {
  width: 75%;
  display: inline-block;
  font: 18px arial;
  padding: 5.5px;
  padding-top: 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 0px;

}
.Shell_newsletterBtn__293uz {
  width: 20%;
  display: inline-block;
  font: 14px arial;
  padding: 6px;
  border: 2px solid #EBB127;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: #EBB127;
  color: #333;
  text-transform: uppercase;
  margin-top: -1px;
}
.Shell_footer__12BcX {
  width: 100%;
  height: auto;
  background: #292828;
  
}

.Shell_copyright__MT4TP {
  background: #121212;
  color: #444;
  font: 12px arial;
  padding: 10px 0;
  text-transform: uppercase;
  /* margin-bottom: 55px !important; */
}


.Loader_shellContainer__KGu4q {
    width: 100%;
    height: 100%;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
}

.Loader_progressContainer__y2cLJ {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
}
