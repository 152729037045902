/*
font-family: 'Righteous', cursive;
font-family: 'Baloo Tammudu 2', cursive;
font-family: 'Comfortaa', cursive;
font-family: 'Prompt', sans-serif;
font-family: 'Cormorant Garamond', serif;
font-family: 'Cinzel', serif;
font-family: 'Great Vibes', cursive;
font-family: 'Orbitron', sans-serif;
font-family: 'Poiret One', cursive;
font-family: 'Long Cang', cursive;
font-family: 'Yellowtail', cursive;
font-family: 'Bad Script', cursive;
font-family: 'Allura', cursive;

*/
/* * {
  font-family: 'Cinzel', serif;
} */

@media only screen and (max-width: 600px) {
  .logo {
    max-width: 180px;
    left: 0px;
    top: 5px;
  }
}

@media only screen and (min-width: 601px) {
  .logo {
    max-width: 280px;
    left: 0px;
    top: 0px;
  }
}

@media only screen and (min-width: 1201px) {
  .logo {
    max-width: 320px;
    top: 0px;
    left: 10px;
  }
}

.logo {
   padding: 10px;
  position: absolute;

  z-index: 100;
}

.newsletter {
  padding: 60px 0 30px 0;
}

.newsletterShell {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  background: transparent;
  padding: 10px;
  display: block;
  text-align: center;

}
 
.newsletterTitle {
  color: #777;
}
.newsletterInput {
  width: 75%;
  display: inline-block;
  font: 18px arial;
  padding: 5.5px;
  padding-top: 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 0px;

}
.newsletterBtn {
  width: 20%;
  display: inline-block;
  font: 14px arial;
  padding: 6px;
  border: 2px solid #EBB127;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: #EBB127;
  color: #333;
  text-transform: uppercase;
  margin-top: -1px;
}
.footer {
  width: 100%;
  height: auto;
  background: #292828;
  
}

.copyright {
  background: #121212;
  color: #444;
  font: 12px arial;
  padding: 10px 0;
  text-transform: uppercase;
  /* margin-bottom: 55px !important; */
}

